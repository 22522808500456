import React from "react";
import bannerData from "../data/data.json";
import "./shipping.css";

function shipping() {
  return (
    <div className="shipping-style">
      <div className="container background-style">
        <div className="row">
          <div className="col-12">
            <h2 className="shipping-title">
              {bannerData.services.shipping[0].shippingtitle}
            </h2>
          </div>
        </div>
        <br></br>
        <div className="row row-style">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
            <div className="shipping-image"></div>
            <h4 className="image-title">
              {bannerData.services.shipping[0].shippingimagetitle}
            </h4>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
            <div className="shipping-image1"></div>
            <h4 className="image-title1">
              {bannerData.services.shipping[0].shippingimagetitle1}
            </h4>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
            <div className="shipping-image2"></div>
            <h4 className="image-title2">
              {bannerData.services.shipping[0].shippingimagetitle2}
            </h4>
          </div>
        </div>
        <br></br>
        <div className="row row-style">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
            <div className="shipping-image3"></div>
            <h4 className="image-title3">
              {bannerData.services.shipping[0].shippingimagetitle3}
            </h4>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
            <div className="shipping-image4"></div>
            <h4 className="image-title4">
              {bannerData.services.shipping[0].shippingimagetitle4}
            </h4>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
            <div className="shipping-image5"></div>
            <h4 className="image-title5">
              {bannerData.services.shipping[0].shippingimagetitle5}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}
export default shipping;
