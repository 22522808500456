import React, { useState, useEffect } from "react";
import SimpleSlider from "./AutoshipBanner/slider";
import Header from "./header/header";
import Description from "./description/description";
import Shipping from "./shipping/shipping";
import Footer from "./footer/footer";
import Wheel from "./autoshipflip/flipcard";
import Testimonials from "./testimonials/testimonials";
import servicesCard from "./data/data.json";
import Benefits from "./benefits/benefits";
import ReactGA from "react-ga";

function Home() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="main">
      <main>
        <Header></Header>
        <SimpleSlider></SimpleSlider>
        <Description></Description>
        <br></br>
        <div className="single-slide-container1">
          <div className="servicesContianer justify-center pb-16">
            <div className="w-full servicesContainer mx-auto pt-4">
              <p className="text-white text-xl servicesTitle1 p-2">
                طرق شحن السيارات/ المركبات
              </p>
            </div>
            <div className="servicesContainer flex justify-center ">
              <div className="row padding-poster ">
                <div className=" col-xs-6 col-sm-6 col-md-6 col-lg-4 ">
                  <Wheel
                    cardDetails={servicesCard?.services?.flipcardsservices[0]}
                  ></Wheel>
                </div>
                <div className=" col-xs-6 col-sm-6 col-md-6 col-lg-4 ">
                  <Wheel
                    cardDetails={servicesCard?.services?.flipcardsservices[2]}
                  ></Wheel>
                </div>
                <div className=" col-xs-6 col-sm-6 col-md-6 col-lg-4 ">
                  <Wheel
                    cardDetails={servicesCard?.services?.flipcardsservices[1]}
                  ></Wheel>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Shipping></Shipping>
        <div className="whyContainer flex">
          <Benefits></Benefits>
        </div>
        <br></br>
        <Testimonials></Testimonials>
        <Footer></Footer>
        <></>
      </main>
    </div>
  );
}
export default Home;
