import React, { useState, useEffect, useRef } from "react";
import "./testimonials.css";
import ReactGA from "react-ga";

function Testimonials() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const [slidesCount, setSlidesCount] = useState(
    window.innerWidth <= 980 ? 8 : 4
  );
  let slide = useRef(null);
  // const slidesCount = window.innerWidth <= 768 ?20:10;
  useEffect(() => {
    const handleResize = () => {
     
      changeSlide(false);
      setSlidesCount(window.innerWidth <= 980 ? 8 : 4);
    };

    handleResize();
    changeSlide(false);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const maxLeft = (slidesCount - 1) * 100 * -1;

  let current = 0;

  function changeSlide(next = true) {
    if (next) {
      current = current > maxLeft ? current - 100 : maxLeft;
    } else {
      current = current < 0 ? current + 100 : 0;
    }


    if (slide.current) {
      slide.current.style.left = current + "%";
    }
  }
  return (
    <>
      <div className="section-kt1">
        <div className="row">
          <div className="col-12">
            <div className="container-rwt overflow-wxy">
              <div>
                <div className="subtitle-a6c">
                  <p className="subtitle-qto">الشهادات</p>
                  <div className="subtitle-aqf"></div>
                </div>
                <h2 className="section-bjn"></h2>
              </div>

              <div className="carousel">
                <div
                  className="slides  testimonial-slide"
                  ref={slide}
                  style={{ left: 0, transition: "left 0.5s ease" }}
                >
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">ديفيد</h3>
                            <p className="author-j5t">تيما، غانا</p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        تعاملت شركة اوفرسيز شيبينغ (Overseas Shipping) مع شحن
                        سيارتي بشكلٍ مهنيٍّ من المعرض في الولايات المتحدة
                        الأمريكية إلى تيما. وقامت الشركة أيضًا بتخليص جميع أوراق
                        السيارة من الجمارك.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              جيم ويز
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              ألمانيا
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        وصلت سيارتي في الوقت المحدد دون أي ضرر وفي الوقت المحدد.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">علي وهدان</h3>
                            <p className="author-j5t">الجزائر</p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        خدمة ممتازة. كان الممثلون ودودين ومفيدين ومهذبين. وصلت
                        مركبتي إلى الوجهة في الوقت المناسب وقطعة واحدة. أنا أرشح
                        هذه الشركة بشدة لأي شخص يرغب في شحن مركبته.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              تيمي سميث
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              المملكة المتحدة
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        حصلت على سيارتي في لندن. كان كل شيء سلسًا. لا توجد جروح
                        أو خدوش. لقد ذهبت بالفعل للتسوق، لكن هؤلاء الرجال كانوا
                        الأرخص ويبدو أنهم الأكثر موثوقية. لم أضطر إلى التواصل
                        معهم إلا عدة مرات خلال العملية لمعرفة تاريخ الشحن
                        التقريبي.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">هوب فور لايف</h3>
                            <p className="author-j5t">تيما، غانا</p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        كانت عملية حجز النقل معهم سهلة من البداية إلى النهاية.
                        تم تنفيذ كل شيء في الموعد المحدد وكانت التكلفة معقولة
                        جدًا نظرًا للمسافة. بالتأكيد سأرشح هذه الشركة.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              نانسي وايت
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              هيوستن، تكساس
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        لم تكن تجربتي مع شركة اوفرسيز شيبينغ Overseas Shipping
                        إيجابية فقط... الخدمة التي تلقيتها كانت مثالية... وشخصية
                        للغاية وأعطتني راحة كبيرة بأن سيارتي ستصل إلى وجهتها
                        بأمان وبسرعة. أنا أرشحكم لجميع أصدقائي الذين يرغبون بشحن
                        سياراتهم. أنتم الأفضل بكل بساطة.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-YArlP" id="style-YArlP">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3 className="author-jl3">عدنان لسواد</h3>
                            {/* <p className="author-j5t">Nigeria</p> */}
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="person_detail">
                        شحن سريع وخدمة عملاء صادقة. أرشحهم بشدة لأي شخص يريد
                        الشحن للخارج.
                      </p>
                    </div>
                  </div>
                  <div className="slide">
                    <div className="card-aqx style-WGtil" id="style-WGtil">
                      <div className="testimonial-top-ni8">
                        <div className="testimonial-author-8jm">
                          <div>
                            <h3
                              className="author-jl3 style-LC6Og"
                              id="style-LC6Og"
                            >
                              تشارلز شينكا
                            </h3>
                            <p
                              className="author-j5t style-7p6Sv"
                              id="style-7p6Sv"
                            >
                              نيجيريا
                            </p>
                          </div>
                        </div>
                        <div className="icon-ehy yellow-ovm">
                          <img src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63c90552ec00415c71ab3b34_quote-icon.svg"></img>
                        </div>
                      </div>
                      <p className="style-aXEXt" id="style-aXEXt">
                        وجدت هذه الشركة عبر الإنترنت وقررت استئجارها لنقل سيارتي
                        بناءً على خدمة العملاء الجيدة التي حصلت عليها، بالإضافة
                        إلى أن السعر كان مناسبًا لميزانيتي. لا توجد شكاوى على
                        الإطلاق، كل شيء كان في الوقت المحدد وفي حدود الميزانية.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carouselArrow flex justify-center">
                <div
                  className="row-va2 slide-hi8 style-KTGA2"
                  id="style-KTGA2"
                  onClick={() => changeSlide(false)}
                >
                  <div className="hid-fp1 slide-8tf"></div>
                  <img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63a690a1a014a5b94ceef00a_testimonial-left-arrow.png"
                    className="style-vnoDw"
                    id="style-vnoDw"
                  ></img>
                </div>
                <div
                  className="row-v6h slide-y5b style-iXr2r"
                  id="style-iXr2r"
                  onClick={() => changeSlide(true)}
                >
                  <div className="hid-fp1 slide-l4v"></div>
                  <img
                    src="https://assets.website-files.com/63a43a1633ad3e2a969a6958/63a68e3c0aa24416f9724b30_testimonial-right-arrow.png"
                    className="style-xOlIt"
                    id="style-xOlIt"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Testimonials;
