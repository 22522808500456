import React, { useState, useEffect } from "react";
import "./slider.css";
import bannerData from "../data/data.json";
import ReactGA from "react-ga";
// import Form from "../autoshipform/form";

function Slider() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  // const [YourName, setYourName] = useState("");
  // const [captcha, setCaptcha] = useState(generateCaptcha());
  // const [captchaInput, setCaptchaInput] = useState("");
  // const [YourEmail, setYourEmail] = useState("");
  // const [PhoneNumber, setPhoneNumber] = useState("");
  // const [City, setCity] = useState("");
  // function validateForm() {
  //   if (YourName.length == 0) {
  //     alert("Enter Your Name ");
  //     return;
  //   }

  //   if (YourEmail.length == 0) {
  //     alert("Enter Your Email");
  //     return;
  //   }

  //   if (PhoneNumber.length < 10) {
  //     alert("Enter Your Phone Number");
  //     return;
  //   }

  //   if (City.length == 0) {
  //     alert("Enter Your City");
  //     return;
  //   }
  //   const [num1, operation, num2] = captcha.split(" "); // Split the captcha string
  //   const expectedAnswer =
  //     operation === "+"
  //       ? parseInt(num1) + parseInt(num2)
  //       : parseInt(num1) - parseInt(num2);

  //   if (parseInt(captchaInput) !== expectedAnswer) {
  //     alert("Captcha is incorrect, please try again.");
  //     setCaptcha(generateCaptcha()); // Generate a new captcha
  //     setCaptchaInput(""); // Reset captcha input
  //     return false;
  //   }

  //   return true;
  // }
  // function generateCaptcha() {
  //   const num1 = Math.floor(Math.random() * 10);
  //   const num2 = Math.floor(Math.random() * 10);
  //   const operation = Math.random() < 0.5 ? "+" : "-";

  //   return `${num1} ${operation} ${num2} = ?`;
  // }
  return (
    <>
      <div className="single-slide-container">
        <div className="w-full">
          <div className="banner-image" alt="">
            <div className="icon-images" alt="iconImages">
              <div>
                <h4 className="imagetext-style">
                  {bannerData.services.banners[0].imagetitle}
                </h4>
              </div>
            </div>
            <div className="icon-images1" alt="iconImages">
              <h4 className="imagetext-style1">
                {bannerData.services.banners[0].imagetitle2}
              </h4>
            </div>
            <div className="icon-images2" alt="iconImages">
              <h4 className="imagetext-style2">
                {bannerData.services.banners[0].imagetitle3}
              </h4>
            </div>
            <div className="icon-images3" alt="iconImages">
              <h4 className="imagetext-style3">
                {bannerData.services.banners[0].imagetitle4}
              </h4>
            </div>
            {/* <Form></Form> */}
          </div>
        </div>
        <div className="title-c6g slideText3">
          {/* <h1 className="page-title">
            {bannerData.services.banners[0].pageTitle}
          </h1> */}
          <p className="subpage-title">
            {bannerData.services.banners[0].subtitle}
          </p>
        </div>
      </div>
    </>
  );
}
export default Slider;
