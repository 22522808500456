import React, { useState, useEffect } from "react";
import "./benefits.css";
import plane from "../assets/auto-shipping4.jpg";
import BestAutoShipping from "../assets/best-auto-shipping-rates.png";
import Efficient from "../assets/efficient-service.png";
import Experience from "../assets/experienced-staff.png";
import Trusted from "../assets/secure-trusted-auto-shipping.png";
import Track from "../assets/track-auto-shipments.png";
import Inventory from "../assets/inventory-management.png";
import Shipments from "../assets/auto-shipping-icon.png";
import ReactGA from "react-ga";

function Benefits() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-6  leftContainer">
          <div className="">
            <div className="mt-5">
              <img className="whyImg" src={plane}></img>
              <div className="left-container-text  ">
                <div className="row">
                  <div className="col-sm-3">
                    {" "}
                    <img src={Shipments} className="roro-shipping"></img>
                  </div>
                  <div
                    className="col-sm-9 roro-shipping-content"
                    style={{ color: "black" }}
                  >
                    {" "}
                    متخصصون شحن المركبات في الولايات المتحدة الأمريكية إلى
                    العالم
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-5 col-md-12 col-lg-12 col-xl-5  benefits-section">
          <div className="subtitle-wrap">
            <p className="subtitle">الفوائد</p>
            <div className="subtitle-border"></div>
          </div>

          <h2 className="section-rx5"> الفوائد المضمونة لخدمات شحن المركبات</h2>
          <p className="pb-4 Benefits-paragraph">
            تبسيط عملية شحن المركبات الدولية من الولايات المتحدة الأمريكية إلى
            موانئ الشرق الأوسط وأفريقيا وأوروبا. ثق بخبرتنا في النقل المتوافق
            والموثوق والتواصل الواضح.
          </p>
          <div className="why-text pt-4">
            <div className="row">
              <div className="col-sm-2 ">
                {" "}
                <div className="icon-qg7">
                  <img src={BestAutoShipping}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-2">
                <h3 className="benefits-Heading">افضل اسعار لشحن السيارات</h3>
              </div>
              <div className="col-sm-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Efficient}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading1">خدمة فعالة</h3>
              </div>
              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Track}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading4">
                  متابعة الشحنه على الإنترنت
                </h3>
              </div>

              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Trusted}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-2">
                <h3 className="benefits-Heading3">شحن مركبات آمن وموثوق</h3>
              </div>
              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Experience}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading2">طاقم ذو خبرة</h3>
              </div>
              <div className="col-sm-2 mt-2">
                {" "}
                <div className="icon-qg7">
                  <img src={Inventory}></img>
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <h3 className="benefits-Heading5">إدارة المخزون</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefits;
