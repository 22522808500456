import Home from "./home";
import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
const TRACKING_ID = "G-VG816RX1VF";
ReactGA.initialize(TRACKING_ID);
function App() {
  return (
    <>
      <ToastContainer autoClose={1000} position="top-center" />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
